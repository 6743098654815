<template>
  <div class="pt-3">
    <div v-if="displayLists.length === 0" class="d-flex align-center justify-center" style="height: 500px">
      <span style="font-size: 20px; text-align: center; margin-top: -60px; color: #ff6e40; font-weight: 500"
        >予定配達の注文が0件です。
      </span>
    </div>
    <v-card v-for="(item, index) in displayLists" :key="index" outlined color="rgb(0, 0, 0, 0)">
      <v-row class="px-3" style="background-color: #ebe9e8">
        <v-col cols="12" sm="4" md="2" lg="2">
          <div>
            <strong>予定配達日</strong>
          </div>
          <div>
            <span>{{ item.sch_ead }}</span>
          </div>
        </v-col>
        <v-col cols="12" sm="8" md="6" lg="7">
          <div style="margin-top: -1px">
            <div>
              <strong>お届け先</strong>
            </div>
            <div></div>
            <div class="mousemove">
              <span style="color: #ff6e40"
                >{{ item.addr_sei }} {{ item.addr_mei
                }}<v-icon style="margin-top: -5px">{{ icons.mdiMenuDown }}</v-icon></span
              >
              <div class="bottomtip">
                <span>〒{{ item.addr_zip }} {{ item.addr_addr1 }} {{ item.addr_addr2 }}</span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="3">
          <div class="d-flex justify-end text-right">
            <strong>定期便番号：</strong>
            <span>{{ item.sch_id }}</span>
          </div>
          <div class="d-flex align-center justify-end" style="font-size: 15px">
            <!-- <span>領収書</span> -->
          </div>
        </v-col>
      </v-row>
      <v-sheet class="mx-6 mb-6 mt-3">
        <v-row>
          <v-col cols="12" sm="8" md="9" lg="10">
            <div>
              <v-row class="ma-1">
                <v-col cols="12" sm="4" md="3" lg="2">
                  <v-img :src="item.image"></v-img>
                </v-col>
                <v-col cols="12" sm="8" md="9" lg="10">
                  <div style="font-size: 20px; font-weight: bold">
                    <span>{{ item.subs_name }}</span>
                  </div>
                  <div style="font-size: 16px" class="mt-1">
                    <span>数量：</span>
                    <span>{{ item.order_qty }}</span>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" sm="4" md="3" lg="2">
            <div v-if="item.sch_status_code === 1 || item.sch_status_code === 8" class="d-flex justify-end my-3">
              <div style="min-width: 180px">
                <strong style="font-size: 16px">{{ item.sch_cdd }}</strong>
                <span style="font-size: 15px">まで変更可能</span>
              </div>
            </div>
            <div v-if="item.sch_status_code === 1" class="d-flex justify-end my-3">
              <div class="status">
                <span>通常配送</span>
              </div>
            </div>
            <div v-if="item.sch_status_code === 8" class="d-flex justify-end my-3">
              <div class="status">
                <span>一回スキップ</span>
              </div>
            </div>
            <div v-if="item.sch_status_code === 1" class="d-flex justify-end my-3">
              <v-btn depressed color="#ff6e40" width="180px" @click="openDialog1(item)">
                <span style="color: #fff">スキップ</span>
              </v-btn>
            </div>
            <div v-if="item.sch_status_code === 8" class="d-flex justify-end my-3">
              <v-btn depressed color="#ff6e40" width="180px" @click="openDialog2(item)">
                <span style="color: #fff">配送回復</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card>
    <v-dialog v-model="dialog1" persistent width="500">
      <v-card height="220px" class="d-flex align-center justify-center">
        <div>
          <div class="mt-4" style="text-align: center; font-weight: bold; font-size: 20px">
            <span>選択された配送分をスキップしますか？</span>
          </div>
          <div class="d-flex justify-center mt-8">
            <v-btn width="80px" outlined depressed class="mr-6" @click="dialog1 = false">
              <span>いいえ</span>
            </v-btn>
            <v-btn color="#ff6e40" width="80px" depressed @click="skip">
              <span style="color: #fff">はい</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" persistent width="500">
      <v-card height="220px" class="d-flex align-center justify-center">
        <div>
          <div class="mt-4" style="text-align: center; font-weight: bold; font-size: 20px">
            <span>選択された配送分を回復しますか？</span>
          </div>
          <div class="d-flex justify-center mt-8">
            <v-btn width="80px" outlined depressed class="mr-6" @click="dialog2 = false">
              <span>いいえ</span>
            </v-btn>
            <v-btn color="#ff6e40" width="80px" depressed @click="undoSkip">
              <span style="color: #fff">はい</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-pagination
      v-if="displayLists.length > 0"
      v-model="page"
      class="my-6"
      :length="length"
      :total-visible="7"
      color="#ff6e40"
      @input="pageChange"
    ></v-pagination>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { mdiMenuDown } from '@mdi/js'

export default {
  props: ['searchList', 'resetList'],
  data: () => ({
    icons: {
      mdiMenuDown,
    },
    dialog1: false,
    dialog2: false,
    skipId: '',
    date: ['過去1が月', '過去3が月', 'すべて'],
    selectedDate: 'すべて',
    List: [],
    selectedList: [],
    month: '',
    count: '',
    displayLists: [],
    page: 1,
    length: 0,
    pageSize: 10,
  }),
  watch: {
    selectedDate() {
      this.selectedList = []
      if (this.selectedDate === '過去1が月') {
        this.month = -30
        this.select()
      }
      if (this.selectedDate === '過去3が月') {
        this.month = -90
        this.select()
      }
      if (this.selectedDate === 'すべて') {
        this.List = []
        this.refreshList()
      }
    },
    searchList() {
      this.displayLists = this.searchList
      this.length = 1
      this.selectedDate = ''
      this.count = 1
    },
    resetList() {
      this.reset()
    },
  },
  computed: {
    ...mapState('scheduleStore', ['scheduleList']),
  },
  created() {
    this.refreshList()
  },
  methods: {
    ...mapActions('scheduleStore', ['loadScheduleList', 'skipSchedule', 'undoSkipSchedule']),

    refreshList() {
      this.loadScheduleList().then(() => {
        this.scheduleList.forEach((il, i) => {
          if (il.sch_status_code === 1 || il.sch_status_code === 8) {
            this.List.push(this.scheduleList[i])
          }
        })
        this.List.forEach((il, i) => {
          if (il.images) {
            this.List[i].image = `${il.imgUrl_prefix}/${il.images.replaceAll('"', '').split(',')}`
          }
        })

        this.List = this.List.sort((a, b) => b.sch_id - a.sch_id)
        this.displayLists = this.List.slice(this.pageSize * (this.page - 1), this.pageSize * this.page)
        this.length = Math.ceil(this.List.length / this.pageSize)
        this.count = `${this.List.length}件`
      })
    },
    reset() {
      this.List = []
      this.refreshList()
    },
    openDialog1(item) {
      this.skipId = item.sch_id
      this.dialog1 = true
    },
    openDialog2(item) {
      this.skipId = item.sch_id
      this.dialog2 = true
    },
    skip() {
      this.skipSchedule(this.skipId).then(() => {
        this.dialog1 = false
        this.skipId = ''
        this.$emit('doReset')
      })
    },
    undoSkip() {
      this.undoSkipSchedule(this.skipId)
        .then(() => {
          this.dialog2 = false
          this.skipId = ''
          this.$emit('doReset')
        })
        .catch(err => {
          console.error(err)
        })
    },
    select() {
      let td = new Date()
      td = new Date(td.getFullYear(), td.getMonth(), td.getDate())
      this.List.forEach((item, i) => {
        let od = new Date(item.sch_ead)
        od = new Date(od.getFullYear(), od.getMonth(), od.getDate())
        const xc = (od - td) / (24 * 3600 * 1000)
        if (xc >= this.month) {
          this.selectedList.push(this.List[i])
          this.selectedList = this.selectedList.sort((a, b) => b.sch_id - a.sch_id)
          this.displayLists = this.selectedList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page)
          this.length = Math.ceil(this.selectedList.length / this.pageSize)
          this.count = `${this.selectedList.length}件`
        }
      })
    },
    pageChange(pageNumber) {
      this.displayLists = this.List.slice(this.pageSize * (pageNumber - 1), this.pageSize * pageNumber)
      window.scrollTo(0, 0)
    },
  },
}
</script>
<style scoped>
.status {
  min-width: 180px;
  min-height: 37px;
  padding-top: 6px;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  font-size: 15px;
}
.bottomtip {
  display: none;
}
.mousemove :hover {
  cursor: pointer;
  text-decoration: underline;
}
.mousemove:hover .bottomtip {
  display: block;
}
</style>
