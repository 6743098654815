<template>
  <v-app>
    <v-sheet class="py-12 px-6" color="#F8ECDC" height="100%" min-width="375px">
      <Overlays :is-absolute="true" :is-show="absoluteOverlayStatus"></Overlays>
      <div class="mt-12 mb-6" style="font-size: 30px">
        <span>定期便スケジュール</span>
      </div>
      <div>
        <v-tabs
          color="#ff6e40"
          active-class="selectedTab"
          background-color="#FFF8E1"
          slider-color="#fff"
          elevation="6"
        >
          <v-tab href="#1" class="me-1" style="min-width: 50%"> 配送スケジュール </v-tab>
          <v-tab-item value="1" background-color="#F8ECDC">
            <Schedule :search-list="searchList" :reset-list="resetList" @doReset="doReset" />
          </v-tab-item>
          <v-tab href="#2" class="me-1" style="min-width: 50%"> 各種設定 </v-tab>
          <v-tab-item value="2">
            <Setting :search-list="searchList" :reset-list="resetList" @doReset="doReset" />
          </v-tab-item>
        </v-tabs>
      </div>
    </v-sheet>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Overlays from '@/components/Overlays.vue'
import Schedule from './components/Schedule.vue'
import Setting from './components/Setting.vue'

export default {
  components: {
    Schedule,
    Setting,
    Overlays,
  },
  data: () => ({
    List: [],
    search: '',
    searchList: [],
    resetList: false,
  }),
  computed: {
    ...mapState('scheduleStore', ['scheduleList']),
    ...mapState('app', ['absoluteOverlayStatus']),
  },
  created() {
    this.refreshList()
  },
  methods: {
    ...mapActions('scheduleStore', ['loadScheduleList']),
    ...mapMutations('app', ['setAbsoluteOverlayStatus', 'setOverlayStatus']),

    doReset() {
      this.resetList = !this.resetList
    },
    refreshList() {
      this.setAbsoluteOverlayStatus(true)
      this.loadScheduleList().then(() => {
        this.List = [...this.scheduleList]
        this.setAbsoluteOverlayStatus(false)
      })
    },
  },
}
</script>

<style scoped>
.selectedTab {
  background-color: #fff  !important;
}
.v-text-field--outlined >>> fieldset {
  border-color: #ff6e40;
  border-width: 2px;
}
</style>
